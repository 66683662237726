body { background-color: $COL1; }
body { color: $COL2; }
[data-pad="0"] { margin-top: 20px; }

[data-pad="1"] { margin-top: 5px; }

h1 {
color: $COL12;
font-family: 'Oswald';
font-weight: normal;
font-style: normal;
line-height: 1.5;
text-transform: uppercase;
font-size: 25px;

@media #{$medium-up} {
font-size: 40px;

}
}
h2 {
color: $COL13;
font-family: 'Oswald';
font-weight: normal;
font-style: normal;
line-height: 1.5;
text-transform: uppercase;
font-size: 22px;

@media #{$medium-up} {
font-size: 35px;

}
}
h3 {
color: $COL14;
font-family: 'Oswald';
font-weight: normal;
font-style: normal;
line-height: 1.5;
text-transform: uppercase;
font-size: 20px;

@media #{$medium-up} {
font-size: 30px;

}
}
h4 {
color: $COL15;
font-family: 'Oswald';
font-weight: normal;
font-style: normal;
line-height: 1.5;
text-transform: uppercase;
font-size: 18px;

@media #{$medium-up} {
font-size: 25px;

}
}
h5 {
color: $COL16;
font-family: 'Oswald';
font-weight: normal;
font-style: normal;
line-height: 1.5;
text-transform: uppercase;
font-size: 17px;

@media #{$medium-up} {
font-size: 20px;

}
}
h6 {
color: $COL17;
font-family: 'Oswald';
font-weight: normal;
font-style: normal;
line-height: 1.5;
text-transform: uppercase;
font-size: 16px;

@media #{$medium-up} {
font-size: 18px;

}
}
.button {
font-family: 'Oswald';
font-weight: normal;
font-style: normal;
line-height: 1.5;
font-size: 14px;

@media #{$medium-up} {
font-size: 16px;

}
}
.me-Quote .quote-body {
font-family: 'Open Sans';
font-weight: normal;
font-style: normal;
line-height: 1.5;
font-size: 14px;

@media #{$medium-up} {
font-size: 16px;

}
}
.me-Quote .quote-author {
font-family: 'Open Sans';
font-weight: normal;
font-style: normal;
line-height: 1.5;
font-size: 14px;

@media #{$medium-up} {
font-size: 16px;

}
}
ol,ul {
color: $COL18;
font-family: 'Oswald';
font-weight: 300;
font-style: normal;
line-height: 1.5;
font-size: 14px;

@media #{$medium-up} {
font-size: 16px;

}
}
ol,ul { margin-bottom: 1em; list-style-position: outside; }
ol { margin-left: 1.25em; list-style-type: decimal; }
ul { margin-left: 1.25em; list-style-type: disc; }
ul, ol { & & { margin-bottom: 0 } }
cite {
font-family: 'Open Sans';
font-weight: normal;
font-style: normal;
line-height: 1.5;
font-size: 14px;

@media #{$medium-up} {
font-size: 16px;

}
}
summary {
font-family: 'Oswald';
font-weight: normal;
font-style: normal;
line-height: 1.5;
text-transform: uppercase;
font-size: 18px;

@media #{$medium-up} {
font-size: 25px;

}
}
nav.mainmenu > .menu-item > a {
font-family: 'Oswald';
font-weight: normal;
font-style: normal;
line-height: 1.5;
text-transform: uppercase;
font-size: 14px;

@media #{$medium-up} {
font-size: 16px;

}
@media #{$large-up} {
font-size: 20px;

}
}
nav.mainmenu > .menu-item > div > a {
font-family: 'Oswald';
font-weight: normal;
font-style: normal;
line-height: 1.5;
text-transform: uppercase;
font-size: 14px;

@media #{$medium-up} {
font-size: 16px;

}
@media #{$large-up} {
font-size: 20px;

}
}
nav.secondarymenu > .menu-item > a {
font-family: 'Oswald';
font-weight: normal;
font-style: normal;
line-height: 1.5;
font-size: 14px;

@media #{$medium-up} {
font-size: 16px;

}
}
nav.me-RespMenu.responsive-menu a {
font-family: 'Oswald';
font-weight: 300;
font-style: normal;
line-height: 2.5;
font-size: 20px;

}
body {
font-family: 'Oswald';
font-weight: 300;
font-style: normal;
line-height: 2.5;
font-size: 16px;

}
p { padding-bottom: 1em; }
a {color: $COL27}
a:hover {color: $COL3}
/* Shade 2:14 */
.MES14 {
background-color: $COL25;
color: $COL10;
 }
/* Main Menu:24 */
nav.me-Menu.MES24 {
& .menu-item.MEC24, & .menu-item.MEC24 > div.MEC24{ & > a.MEC24{color: $COL19;
}
 &:hover > a.MEC24{color: $COL19;
}
 }
&.horizontal > .menu-item.MEC24 { border:0;

 border-right-width: 1px;}
&.vertical > .menu-item.MEC24 { border:0;

border-bottom-width: 1px; }
&.horizontal > .menu-item.MEC24 .menu-item { border:0;
border-bottom-width: 1px;
 }
&.horizontal > .menu-item.MEC24 .sub-menu { border:1px;
 }
& > .menu-item > a{padding: 10px 15px;}

& .sub-menu .menu-item a{padding: 5px 10px;}



.menu-item:hover {
& > .sub-menu, & > .pointer-wrap > .sub-menu {

}
}
 }
/* Top Menu:23 */
nav.me-Menu.MES23 {
& .menu-item.MEC23, & .menu-item.MEC23 > div.MEC23{ & > a.MEC23{color: $COL20;
}
 &:hover > a.MEC23{color: $COL20;
}
 }
&.horizontal > .menu-item.MEC23 { border:0;

 border-right-width: 1px;}
&.vertical > .menu-item.MEC23 { border:0;

border-bottom-width: 1px; }
&.horizontal > .menu-item.MEC23 .menu-item { border:0;
border-bottom-width: 1px;
 }
&.horizontal > .menu-item.MEC23 .sub-menu { border:1px;
 }
& > .menu-item > a{padding: 10px 15px;}

& .sub-menu .menu-item a{padding: 5px 10px;}



.menu-item:hover {
& > .sub-menu, & > .pointer-wrap > .sub-menu {

}
}
 }
/* Responsive Menu:22 */
nav.responsive-menu {
.menu-item.MEC22{background-color: $COL19;
}
& .menu-item.MEC22, & .menu-item.MEC22 > div.menu-item-wrap{ & > a.MEC22, & > i{color: $COL1;
font-size: 16px;
text-align: center;
}
  }
& .menu-item.MEC22 { border:0;
border-color: $COL1;
border-style: dotted;

 border-bottom-width: 1px; }
& .menu-item > .menu-item-wrap {padding: 5px 0;}

& .sub-menu{ .sub-menu {}}

 }
/* Contact Form:21 */
.me-block.me-Form.MES21,  body.MES21 {background-color:transparent;
  
 input[type='submit']{border-style: solid;background-color: $COL19;
&:hover {background-color: $COL19}
border-width: 0;
color: $COL1;
&:hover {color: $COL1}
padding: 10px 20px;

}

  }
/* Padding :20 */
.MES20 {
color: $COL23;
padding: 0 40px;

@media #{$medium-up} {
padding: 0 20px;

}
 }
cite.MEC20{
color: $COL23;
}
/* Phone number:19 */
.MES19 {
background-color: $COL19;
font-size: 22.4px;
@media #{$large-up} {
font-size: 24px;
};
 }
h1.MEC19, h2.MEC19, h3.MEC19, h4.MEC19, h5.MEC19, h6.MEC19 { color: $COL5;
 }
a.MEC19 { color: $COL1;
&:hover { color: $COL20; }
 }
cite.MEC19{
font-size: 22.4px;
@media #{$large-up} {
font-size: 24px;
};
}
/* Shade 3:18 */
.MES18 {
background-color: $COL24;
color: $COL11;
min-height:100px;
@media #{$medium-up} {
min-height: 110px;};
@media #{$large-up} {
min-height: 110px;};
border-width: 0 0 5px 0;
border-style: solid;
border-color: transparent transparent $COL22 transparent;
 }
h1.MEC18, h2.MEC18, h3.MEC18, h4.MEC18, h5.MEC18, h6.MEC18 { color: $COL11;
 }
cite.MEC18{
color: $COL11;
}
/* Shade 3:17 */
.MES17 {
background-color: $COL24;
color: $COL11;
 }
/* Shade 2:16 */
.MES16 {
background-color: $COL25;
color: $COL10;
 }
h1.MEC16, h2.MEC16, h3.MEC16, h4.MEC16, h5.MEC16, h6.MEC16 { color: $COL10;
 }
cite.MEC16{
color: $COL10;
}
/* Shade 2:15 */
.MES15 {
color: $COL10;
 }
h1.MEC15, h2.MEC15, h3.MEC15, h4.MEC15, h5.MEC15, h6.MEC15 { color: $COL10;
 }
cite.MEC15{
color: $COL10;
}
/* Shade 1:13 */
.MES13 {
background-color: $COL26;
color: $COL9;
 }
h1.MEC13, h2.MEC13, h3.MEC13, h4.MEC13, h5.MEC13, h6.MEC13 { color: $COL9;
 }
cite.MEC13{
color: $COL9;
}
/* Primary:2 */
.MES2 {
background-color: $COL20;
color: $COL4;
 }
/* Shade 1:12 */
.MES12 {
background-color: $COL26;
color: $COL9;
 }
/* Dark:11 */
.MES11 {
background-color: $COL23;
color: $COL8;
 }
h1.MEC11, h2.MEC11, h3.MEC11, h4.MEC11, h5.MEC11, h6.MEC11 { color: $COL8;
 }
cite.MEC11{
color: $COL8;
}
/* Dark:10 */
.MES10 {
background-color: $COL23;
color: $COL8;
 }
/* Light:9 */
.MES9 {
background-color: $COL22;
color: $COL7;
 }
h1.MEC9, h2.MEC9, h3.MEC9, h4.MEC9, h5.MEC9, h6.MEC9 { color: $COL7;
 }
cite.MEC9{
color: $COL7;
}
/* Light:8 */
.MES8 {
background-color: $COL22;
color: $COL7;
 }
/* Alternate:7 */
.MES7 {
background-color: $COL21;
color: $COL6;
 }
h1.MEC7, h2.MEC7, h3.MEC7, h4.MEC7, h5.MEC7, h6.MEC7 { color: $COL6;
 }
a.MEC7 { color: $COL20;
&:hover { color: $COL19; }
 }
cite.MEC7{
color: $COL6;
}
/* Alternate:6 */
.MES6 {
background-color: $COL21;
color: $COL6;
 }
/* Secondary:5 */
.MES5 {
background-color: $COL19;
color: $COL5;
 }
h1.MEC5, h2.MEC5, h3.MEC5, h4.MEC5, h5.MEC5, h6.MEC5 { color: $COL5;
 }
cite.MEC5{
color: $COL5;
}
/* Secondary:4 */
.MES4 {
background-color: $COL19;
color: $COL5;
 }
/* Primary:3 */
.MES3 {
background-color: $COL20;
color: $COL1;
font-size: 12.8px;
 }
h1.MEC3, h2.MEC3, h3.MEC3, h4.MEC3, h5.MEC3, h6.MEC3 { color: $COL4;
 }
a.MEC3 { color: $COL1;
&:hover { color: $COL19; }
 }
cite.MEC3{
color: $COL1;
font-size: 12.8px;
}
/* Ahmeds test:25 */
.MES25 {
background-color: $COL19;
color: $COL1;
font-size: 22.4px;
@media #{$large-up} {
font-size: 24px;
};
 }
h1.MEC25, h2.MEC25, h3.MEC25, h4.MEC25, h5.MEC25, h6.MEC25 { color: $COL5;
 }
a.MEC25 { color: $COL1;
&:hover { color: $COL20; }
 }
cite.MEC25{
color: $COL1;
font-size: 22.4px;
@media #{$large-up} {
font-size: 24px;
};
}
