$COL1: #ffffff; //
$COLFLAT1: #ffffff; //
$COL2: #363435; //
$COLFLAT2: #363435; //
$COL3: #363435; //
$COLFLAT3: #363435; //
$COL4: #ffffff; //
$COLFLAT4: #ffffff; //
$COL5: #ffffff; //
$COLFLAT5: #ffffff; //
$COL6: #1d1d1d; //
$COLFLAT6: #1d1d1d; //
$COL7: #1d1d1d; //
$COLFLAT7: #1d1d1d; //
$COL8: #ffffff; //
$COLFLAT8: #ffffff; //
$COL9: #ffffff; //
$COLFLAT9: #ffffff; //
$COL10: #ffffff; //
$COLFLAT10: #ffffff; //
$COL11: #1d1d1d; //
$COLFLAT11: #1d1d1d; //
$COL12: #363435; //
$COLFLAT12: #363435; //
$COL13: #363435; //
$COLFLAT13: #363435; //
$COL14: #363435; //
$COLFLAT14: #363435; //
$COL15: #363435; //
$COLFLAT15: #363435; //
$COL16: #363435; //
$COLFLAT16: #363435; //
$COL17: #363435; //
$COLFLAT17: #363435; //
$COL18: #4a9fd3; //
$COLFLAT18: #4a9fd3; //
$COL19: #4a9fd3; //
$COLFLAT19: #4a9fd3; //
$COL20: #363435; //
$COLFLAT20: #363435; //
$COL21: #e8e8e8; //
$COLFLAT21: #e8e8e8; //
$COL22: #f2f2f2; //
$COLFLAT22: #f2f2f2; //
$COL23: #1d1d1d; //
$COLFLAT23: #1d1d1d; //
$COL24: #ffffff; //
$COLFLAT24: #ffffff; //
$COL25: #c1c1c1; //
$COLFLAT25: #c1c1c1; //
$COL26: #333333; //
$COLFLAT26: #333333; //
$COL27: #4a9fd3; //
$COLFLAT27: #4a9fd3; //
$COL28: rgba(0,0,0,0); //
$COLFLAT28: #808080; //
$breakpoints: (
  small: 0,
  medium: 640px,
  large: 1024px,
  xlarge: 1248px,
  xxlarge: 1936px,
);
$global-width: 1200px;
