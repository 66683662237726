figure{margin:0;}

// Slider Style
.me-owrap.me-Slider,
.me-block.me-Slider{
  position: relative;
  .slider-arrow{
    position: absolute;
    top: calc(50% - 30px);
    transform: translateY(-50%);
    font-size:60px;
    color: white;
    cursor: pointer;
    opacity: 0.5;
    &.slider-arrow-left{left:10px;}
    &.slider-arrow-right{right:10px;}
    @media screen and (min-width: 641px) {
      font-size: 115px;
    }
  }
  .slick-dots{
    li {
      button{
        background-color: #ffa500;
        //background-color: #FF0000;
        height: 20px;
        width: 20px;
        border-radius: 20px;
        position: relative;
        opacity: 0.6;
        &:hover{
          background-color: #FF0000;
          //background-color: #ffa500;
        }
      }
      button:before {
        content: "";
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        opacity: 0.8;
        background-color: white;
        width: 8px;
        height: 8px;
        border-radius: 8px;
      }
      &.slick-active{
        button{
          background-color: #FF0000;
          //background-color: #ffa500;
        }
      }
    }
    bottom: 10px;
    display:none;
    @media screen and (min-width: 641px) {
      display:block;
    }
  }
}

.logo{padding:15px;}

.me-block.me-Form{
  input[type="submit"]{
    margin: 10px auto 20px;
  }
}



// backend only style
.screen[data-mebuild-view="desktop"] {
  .me-owrap.me-Menu.main-menu{
    nav > .menu-item {
      & > a{font-size: 18px;}
    }
  }
}

.screen[data-mebuild-view="mobile"] {
  .me-owrap.me-Slider{
    .slider-arrow{
      font-size: 50px;
    }
  }
  .footer{
    .me-block.me-CKEditor{
      text-align:center;
    }
  }
}