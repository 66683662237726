.me-block.me-Menu.main-menu,
.me-block.me-Menu.top-menu{
  display:none;
  @media screen and (min-width: 1024px) {
    display: block;
  }
  @media screen and (max-width: 1200px) {
    nav a {
      font-size: 16px;
    }
  }
}

